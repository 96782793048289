import {Box, Stack, StackDivider} from "@chakra-ui/react";
import ProfileCard from "@component/mainPage/Profile/ProfileCard";
import ProfileDetail from "@component/mainPage/Profile/ProfileDetail";
import ProfileSchool from "@component/mainPage/Profile/ProfileSchool";

const ProfileBox = (props) => {

  const {profile, url, school} = props.profileInfo;

  return (<>
    <Box
      bg={"gray.50"}
      borderRadius={"5px"}
      padding={"3rem 1rem"}
    >
      <Stack
        direction={{base:"column", lg:"row"}}
        align="center"
        divider={<StackDivider borderColor="gray.300"/>}
      >
        <ProfileCard profile={profile} url={url}/>
        <ProfileDetail profile={profile}/>
        <ProfileSchool school={school}/>
      </Stack>
    </Box>
  </>)
}

export default ProfileBox;