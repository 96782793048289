import {Heading} from "@chakra-ui/react";

const StackTitle = (props) => {
  return (
    <>
      <Heading as={"h2"} size={"md"} fontWeight={"500"} color={"gray.400"} textAlign={"center"} my={"1rem"}>{props.children}</Heading>
    </>
  )
}

export default StackTitle;