import {Box, Text} from "@chakra-ui/react";
import styled from "@emotion/styled";

const ProfileSchoolItemBox = styled(Box)`
  padding: .5rem;
  border: 1px solid var(--chakra-colors-gray-300);
  border-radius: 5px;
  width: 100%;
  background-color: white;
`;

const ProfileSchoolItem = (props) => {
  const {data} = props;
  return (<>
    <ProfileSchoolItemBox>
      <Text fontWeight={"bold"}>{data.name}</Text>
      <Text fontSize={"sm"}>{data.type}</Text>
      <Text fontSize={"sm"} fontWeight={"bold"}>{data.subject}</Text>
      <Text fontSize={"xs"}>{data.from} ~ {data.to}</Text>
    </ProfileSchoolItemBox>
  </>)
}

export default ProfileSchoolItem;