import {Box, Container, Heading} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {Link, NavLink} from "react-router-dom";

const To = styled(NavLink)`
  padding: .25rem 1rem;
  margin-left: .5rem;
  border-radius: 5px;

  :hover {
    background: rgba(0, 0, 0, 0.4);
    color: white;
  }
`;

const HeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10000;
`;

const Header = () => {

  const navMenus = [
    {to: '/', text: '홈'},
    {to: '/history', text: '상세이력'},
    {to: '/project', text: '프로젝트'},
  ]

  return (<>
    <HeaderBox
      bg="brand.500"
      color="white"
      p="1rem"
    >
      <Container
        maxW="container.lg"
        display="flex"
        justifyContent="space-between"
      >
        <Heading as={'h1'} size={'md'}>
          <Link to={"/"}>EsterKim</Link>
        </Heading>
        <Box>
          {navMenus.map(nav => <To
            to={nav.to}
            className={({isActive}) => (isActive ? 'active' : '')}
          >
            {nav.text}
          </To>)}
        </Box>
      </Container>
    </HeaderBox>
  </>)
}
export default Header;