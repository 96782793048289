import StackTitle from "@component/mainPage/Stack/StackTitle";
import StackList from "@component/mainPage/Stack/StackList";

const StackBox = (props) => {
  const {myStack} = props;

  return (
    <>
      {
        myStack.map(stacks => {
          return (
            <>
              <StackTitle>{stacks.title}</StackTitle>
              <StackList stackList={stacks.stack}></StackList>
            </>
          )
        })
      }
    </>
  )
}

export default StackBox;