import {Badge, Box, Divider, Heading, Stack, Text} from "@chakra-ui/react";
import styled from "@emotion/styled";

const PageTitleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageTitle = (props) => {

  const title = props.title ? props.title : 'title';
  const subtitle = props.subtitle ? props.subtitle : '';

  return (<>
    <PageTitleBox
      h="2xs"
    >
      <Stack direction="column">
        <Heading
          as="h1"
          size="3xl"
          color="brand.700"
          textAlign={"center"}
        >
          {title}
        </Heading>
        {subtitle ? <Text textAlign={"center"} pt=".5rem">
          <Badge fontSize="md" bg="brand.100" fontWeight="normal">{subtitle}</Badge>
        </Text> : <></>}
      </Stack>
    </PageTitleBox>
    <Divider/>
  </>)
}

export default PageTitle;