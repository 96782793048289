import {Box, Heading, Stack} from "@chakra-ui/react";
import ProfileSchoolItem from "@component/mainPage/Profile/ProfileSchoolItem";

const ProfileSchool = (props) => {
  const {school} = props;
  return (<>
    <Box w={{base:"100%", lg:"33%"}}>
      <Stack>
        <Heading as={"h3"} size={"sm"}>학력</Heading>
        {school.map(s => <ProfileSchoolItem data={s}/>)}
      </Stack>
    </Box>
  </>)
}

export default ProfileSchool;