import ActivityBoxItem from "@component/mainPage/Activity/ActivityBoxItem";
import {Stack} from "@chakra-ui/react";

const ActivityBox = () => {

  const activityList = [
    {
      title: "싱가폴 ITE Collage West 해외 교류",
      from: "2019.03",
      to: "2019.12",
      desc: "싱가폴 ITE Collage West 와의 국제교류를 통해 싱가폴의 학생들과 함께 인공지능 개발 프로젝트를 진행하였으며 Tensorflow, Numpy 등의 다양한 라이브러리의 사용법도 학습하였습니다.",
      stacks: ['PYTHON', 'Tensorflow v1', 'LINUX', 'Arduino', 'numpy', 'pandas', 'DNN', 'RNN']
    },
    {
      title: 'Intel AI4YOUTH',
      from: "2019.03",
      to: "2019.12",
      desc: "인텔과 함께 사업을 진행중이었던 인텔이노베이션랩 동아리에 들어가 AI4YOUTH라는 프로그램을 이수하게되어 해당 프로그램을 통해서 처음으로 인공지능이라는 분야를 직접적으로 접하게 되었고, 2019 이러닝코리아에서 발표를 하는 시간도 가질 수 있었습니다.",
      stacks: ['PYTHON', 'Tensorflow v1', 'LINUX', 'Arduino', 'numpy', 'pandas'],
    },
    {
      title: "부산컴퓨터과학고등학교 정보기술동아리",
      from: "2018.03",
      to: "2019.12",
      desc: "부산컴퓨터과학고등학교에서 정보기술동아리에 가입하여, 2년간 JAVA, PowerPoint, Excel, Excel VBA 등 다양한 기술을 학습하고, 전국 기능경기대회, 전국 상업경진대회 등 다양한 대회에 출전하여 성적을 거두었습니다.",
      stacks: ['JAVA', 'JAVA Swing', 'MySQL', 'powerpoint', 'excel', 'excel vba', 'word']
    },
    {
      title: "부산컴퓨터과학고등학교 상상실",
      from: "2018.03",
      to: "2019.12",
      desc: "부산컴퓨터과학고등학교의 상상실 동아리장으로 활동하였고, Arduino, Raspberry Pi, 전자회로, AutoCAD 3D, 3D Printer, 3D Modeling 등을 학습하였고, 2019년에는 AI 선도학교가 됨에 따라 AI스터디, AI활용 서비스 제작등의 활동을 하였습니다.",
      stacks: ['Arduino', 'Raspberry Pi', 'LINUX', 'AUTOCAD', '3D Printer', 'C++', 'PYTHON', 'FLASK', 'pytorch', 'numpy', 'pandas', 'tensorflow', 'java', 'android']
    },
    {
      title: "부산인지중학교 창업동아리",
      from: "2016.03",
      to: "2016.12",
      desc: "부산인지중학교에서 창업동아리를 결성하여 창업 대회에 참가를 하였고, 과학동아에 실리기도하는 등의 성과를 거두었습니다.",
      stacks: ['Arduino', 'Raspberry Pi', 'C++', 'LINUX', 'JAVA', 'Android']
    },
    {
      title: "부산정보영재원",
      from: "2014.03",
      to: "2016.12",
      desc: "부산정보영재원에서 정보영재로 발탁되어, 3년간 C, C++, JAVA, Algorithm, Application 등 다양한 개발분야의 기초를 다질 수 있었습니다.",
      stacks: ['C', 'C++', 'JAVA', 'Android', 'Algorithm']
    },
  ]

  return (<>
    <Stack>
    {
      activityList.map(activity => <ActivityBoxItem activity={activity} />)
    }
    </Stack>
  </>)
}

export default ActivityBox;