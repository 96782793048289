import {extendTheme} from "@chakra-ui/react";

const colors = {
  brand: {
    50: '#f8e8ff',
    100: '#F0CDFF',
    200: '#DC9BFF',
    300: '#C369FF',
    400: '#AB43FF',
    500: '#8305FF',
    600: '#6503DB',
    700: '#4B02B7',
    800: '#350193',
    900: '#25007A',
  },
}

const fonts = {
  heading: "Spoqa Han Sans Neo, sans-serif",
  body: "Spoqa Han Sans Neo, sans-serif"
}

const chakraTheme = extendTheme({
  colors,
  fonts
});

export default chakraTheme;