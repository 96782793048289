import styled from "@emotion/styled";
import {GridItem, Text} from "@chakra-ui/react";

const StackItemBox = styled(GridItem)`
  position: relative;
  padding: 1rem;
  box-shadow: 0 5px 8px rgba(0,0,0,10%);
  border-radius: 1rem;
  background-color: white;
  
  transition: transform .5s ease,
              box-shadow .5s ease;
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 5px 16px rgba(0,0,0,50%);
    cursor: pointer;
    z-index: 100;
  }
`;

const StackItemName = styled(Text)`
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
`;

const StackItemImg = styled.img`
  margin-bottom: 8px;
`;

const StackItem = (props) => {
  return (
    <>
      <StackItemBox>
        <StackItemImg src={`https://imagedelivery.net/_xKSLek34flULXhHsMkGkQ/${props.img}/square`} alt={props.name} />
        <StackItemName>{props.name}</StackItemName>
      </StackItemBox>
    </>
  )
}

export default StackItem;