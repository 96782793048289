import {GridItem, Text} from "@chakra-ui/react";
import styled from "@emotion/styled";

const ProfileDetailTitle = styled(Text)`
  font-weight: bold;
`;
const ProfileDetailContent = styled(Text)`

`;

const ProfileGridItem = (props) => {
  return (
    <>
      <GridItem>
        <ProfileDetailTitle textAlign={{base: "left", lg: "right"}}>{props.title}</ProfileDetailTitle>
      </GridItem>
      <GridItem>
        <ProfileDetailContent>{props.content}</ProfileDetailContent>
      </GridItem>
    </>
  )
}

export default ProfileGridItem;