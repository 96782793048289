import Router from "@module/Router";
import React from "react";
import {Container} from "@chakra-ui/react";
import Header from "@component/layout/Header";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Container maxW="container.lg">
        <Router/>
      </Container>
    </div>
  )
}

export default App;