import {Badge, Box, Heading, Stack, Text, Wrap} from "@chakra-ui/react";
import styled from "@emotion/styled";

const CustomBox = styled(Box)`
  background-color: var(--chakra-colors-gray-50);
  border: 1px solid var(--chakra-colors-gray-200);
  padding: 1rem;
  border-radius: 5px;
`;

const ActivityBoxItem = (props) => {
  const {title, desc, from, to, stacks} = props.activity;
  return (
    <>
      <CustomBox>
        <Stack direction={{base:"column", lg:"row"}} align={{base:"start", lg:"end"}} mb={"1rem"}>
          <Heading
            as={"h2"}
            fontSize={"xl"}
          >
            {title}
          </Heading>
          <Text fontSize={"xs"}>{from} ~ {to}</Text>
        </Stack>
        <Text fontSize={"sm"}>{desc}</Text>
        <Wrap direction={"row"} mt={"1rem"}>
          {stacks &&
            stacks.map(stack => <Badge bg={"brand.100"} color={"brand.900"}>{stack}</Badge>)
          }
        </Wrap>
      </CustomBox>
    </>
  )
}

export default ActivityBoxItem;