import {Heading} from "@chakra-ui/react";
import {AttachmentIcon} from "@chakra-ui/icons";
import {Link} from "react-router-dom";
import {useRef} from "react";

const SectionTitle = ({id, text}) => {
  const titleRef = useRef(null);

  const executeScroll = () => titleRef.current.scrollIntoView()

  return (<>
      <Heading as="h2" py="1rem" fontSize="2xl" color="gray.700" ref={titleRef} id={id}>
        <Link to={`#${id}`} onClick={executeScroll}>
          <AttachmentIcon w={5} mr=".5rem"/>{text}
        </Link>
      </Heading>
    </>)
}

export default SectionTitle;