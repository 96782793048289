import PageTitle from "@component/common/PageTitle";
import SectionTitle from "@component/common/SectionTitle";

const HistoryPage = () => {

  const historyList = [
    {
      title: '사내 Git 서버 구축',
      start: '2021.11',
      end: '2022.01',
      desc: '사내 미사용중인 아이맥을 활용하여 개발환경을 중앙집중개발 환경에서 Git 기반 환경으로 변경 하기 위해, Git 서버 구축 및 외부망 연결을 위한 포워딩, ProxyServer(NginX) 설정등의 업무를 진행했습니다.',
      mystack: [
        'Git 서버 구축',
        'NginX 서버 구축',
        '개발 환경 세팅',
      ],
    },
    {
      title: '부동산 웹 사이트 개발 ( 메인 개발자 )',
      start: '2021.09',
      end: '2022.01',
      desc: '부동산 전문 중개 업체 웹 사이트 개발을 메인 개발자 직책을 맡아 진행하였고, PHP 를 이용한 로직 개발, Python 을 이용한 웹 크롤러 프로그램(GUI), 자동 업로드 프로그램 을 개발하였습니다.',
      mystack: [
        'KakaoMap 활용 매물 위치 및 정보 노출 프로그램 개발 ( Restful API 활용 )',
        '클라이언트 맞춤형 세로 막대 그래프 라이브러리 설계 및 개발( IE 11 지원 )',
        '아파트 동, 구, 단지, 아파트동, 층, 호 들어가는 데이터베이스 설계',
        '데이터베이스 로딩 시간 효율 개선 ( 10초 -> 0.5초 )',
        '통계 프로그램 개발',
        '크롤링 프로그램 기획 및 설계',
        'Python, Flask, Selunium 활용 웹 크롤링 프로그램 및 인터페이스 개발',
        'Python <-> PHP 서버 간 데이터 전송 프로그램 개발',
      ],
    },
    {
      title: '모바일 구인 구직 애플리케이션 개발',
      start: '2021.06',
      end: '2021.09',
      desc: '모바일 구인 구직 애플리케이션( 웹앱 기반 )을 개발하며 전반적인 프론트(Jquery, HTML, JS) 업무를 병행하며, 백엔드(PHP)를 이용하여 주요 로직을 설계하는 작업을 진행했습니다.',
      mystack: [
        '무한스크롤 기능 구현',
        '무한스크롤 시 이전 데이터 중복 없이 불러오게하는 로직 구현',
        '외부 라이브러리 없이 동작하는 채팅 애플리케이션 구현',
        '모바일 알림(Push 알림, Firebase) 기능 구현',
        '가입 환영 메일 전송 기능 구현',
        '웹앱(Java) 버그 픽스',
      ],
    },
  ]

  return (<>
    <PageTitle title={"개발 이력"} subtitle={"제가 걸어온 발자취를 소개합니다"} />
    <SectionTitle id={"history"} text={"전공"} />
  </>)
};

export default HistoryPage;