import {Grid} from "@chakra-ui/react";
import ProfileGridItem from "@component/mainPage/Profile/ProfileGridItem";

const ProfileDetail = (props) => {
  const {profile} = props;

  const profileDetail = [
    {'title': '이름', 'content': profile.name},
    {'title': '닉네임', 'content': profile.nickname},
    {'title': '직업', 'content': profile.job},
    {'title': '생일', 'content': profile.birth},
    {'title': '이메일', 'content': profile.email},
    {'title': '연락처', 'content': profile.phone},
  ]

  return (<>
    <Grid w={{base:"100%", lg:"33%"}} templateColumns={{base: "100px auto",lg:"35% 65%"}} columnGap={2}>
      {
        profileDetail.map(i => <ProfileGridItem title={i.title} content={i.content}/>)
      }
    </Grid>
  </>)
}

export default ProfileDetail;