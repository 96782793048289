import StackItem from "@component/mainPage/Stack/StackItem";
import {Grid} from "@chakra-ui/react";

const StackList = (props) => {

  const {stackList} = props;

  return (
    <>
      <Grid bgColor={"gray.50"} p={"1rem"} borderRadius={"1rem"}
            templateColumns={{base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(6, 1fr)'}}
            gap={4}>
      {
        stackList.map(stack => <StackItem name={stack.name} img={stack.img} />)
      }
      </Grid>
    </>
  )
}

export default StackList;