import {Avatar, Box, Text} from "@chakra-ui/react";
import {AiFillGithub, AiFillInstagram} from "react-icons/ai";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";

const AiFillGithubCustom = styled(AiFillGithub)`
  color: var(--chakra-colors-gray-400);

  &:hover {
    color: var(--chakra-colors-gray-600);
  }
`;

const AiFillInstagramCustom = styled(AiFillInstagram)`
  color: var(--chakra-colors-gray-400);

  &:hover {
    color: var(--chakra-colors-gray-600);
  }
`;

const ProfileCard = (props) => {

  const {profile, url} = props;

  return (<>
    <Box w={{base:"100%", lg:"33%"}} textAlign={"center"}>
      <Avatar size="2xl" src={profile.img}/>
      <Text
        fontWeight={"bold"}
        fontSize={"xl"}
        mt={".5rem"}
      >{profile.name}</Text>
      <Text fontWeight={"light"} fontSize={"sm"} mt={"-.25rem"}>{profile.nickname}</Text>
      <Box
        mt=".75rem"
        display="flex"
        justifyContent="center"
      >
        <Link to={url.github}>
          <AiFillGithubCustom size="24"/>
        </Link>
        <Link to={url.instagram}>
          <AiFillInstagramCustom size="24"/>
        </Link>
      </Box>
    </Box>
  </>)
}

export default ProfileCard;