import PageTitle from "@component/common/PageTitle";
import SectionTitle from "@component/common/SectionTitle";
import ProfileBox from "@component/mainPage/Profile/ProfileBox";
import {Divider} from "@chakra-ui/react";
import ActivityBox from "@component/mainPage/Activity/ActivityBox";
import StackBox from "@component/mainPage/Stack/StackBox";

const MainPage = () => {

  const profileInfo = {
    profile: {
      img: 'https://imagedelivery.net/_xKSLek34flULXhHsMkGkQ/b8f757a3-03aa-4fdc-45e0-6eee199a7400/public',
      name: '김지동',
      nickname: 'EsterKim',
      job: '백수가 되고싶은 개발자',
      birth: '2001.00.00',
      email: 'apitots@kakao.com',
      phone: '010-0000-0000',
    },
    url: {
      github: '//github.com/esterKim',
      instagram: 'secret'
    },
    school: [
      {type: '대학교', subject: '컴퓨터공학과 [전문학사]', name: '대덕대학교', from: '2020.03', to: '2022.02'},
      {type: '고등학교', subject: '스마트소프트웨어과', name: '부산컴퓨터과학고등학교', from: '2017.03', to: '2020.02'}
    ]
  }

  const myStack = [
    {
      title: "BackEnd",
      stack: [
        {name: "NodeJS", img: "e68e8b18-96d0-46b5-a700-c717b041af00"},
        {name: "Flask", img: "ade4e2c1-777a-4a0f-966b-d2a78cb7e800"},
        {name: "Django", img: "031cf154-ae69-48a3-f031-31672b508500"},
        {name: "PHP", img: "e67bb709-1151-489e-178a-30ff51edb500"},
        {name: "SpringBoot", img: "34cfc036-84b6-469a-8be7-43833e350300"},
      ],
    },
    {
      title: "FrontEnd",
      stack: [
        {name: "ReactJS", img: "031b5ab3-8de4-4508-9cdd-efc809256000"},
        {name: "VueJS", img: "a7d5498d-1d7d-4c6e-7ccb-2baed0c8a000"},
        {name: "Svelte", img: 'f84d546e-2ba7-4bc6-f138-514fcb7e0600'},
        {name: "Flutter", img: "10c2f353-8570-423b-7d96-47407ec98200"},
      ]
    },
    {
      title: "Embedded",
      stack: [
        {name: "Arduino", img: "f90fc5a2-5d25-4bab-03d1-c5cc21737400"},
        {name: "Raspberry Pi", img: "bf748b87-f40d-4df3-bd59-48d196562e00"},
      ]
    },
    {
      title: "Mobile",
      stack: [
        {name: "Android JAVA", img: "7d8e915d-0259-4ed0-4aa0-bb2f98f79800"},
        {name: "Flutter", img: "10c2f353-8570-423b-7d96-47407ec98200"},
      ]
    },
    {
      title: "Game",
      stack: [
        {name: "GameMaker", img: "cbf1ffeb-5cc7-4791-73f6-14673d9d1c00"},
        {name: "Unity", img: "9808831b-fc60-4963-8009-99effbe83300"},
      ]
    },
    {
      title: "Language",
      stack: [
        {name: "C", img: "3ecb9fce-9e26-4cdd-123c-a4faf06eb200"},
        {name: "C++", img: "005623bb-31f1-43dc-5b3d-10fc821c4700"},
        {name: "C#", img: "28f364c3-0a7b-4b96-6366-0efb55085300"},
        {name: "JAVA", img: "e71a737b-02d0-49fd-d480-8d811a9a5c00"},
        {name: "JavaScript", img: "336ffa66-145f-4956-1b42-8adf895d4100"},
        {name: "PHP", img: "e67bb709-1151-489e-178a-30ff51edb500"},
        {name: "Python", img: "dcdae8d6-a839-4739-8233-e2feb10a1600"},
        {name: "Visual Basic", img: "ed280895-0157-4046-524e-273b86315100"},
      ]
    },
    {
      title: "Database",
      stack: [
        {name: "MySQL", img: "766bfa64-8415-4d75-9b0e-5782229de900"},
        {name: "MariaDB", img: "4848040b-7949-49ec-ab5f-ce048f234d00"},
        {name: "OracleDB", img: "9e9591cc-12d4-4387-bca4-eeb0f4aaf900"},
        {name: "MongoDB", img: "bf8714e3-f539-4479-a249-fb7e9af4f900"},
        {name: "Firebase", img: "a60cecac-4102-4c6a-83b4-981ef88bf800"},
      ],
    },
    {
      title: "Service",
      stack: [
        {name: "Docker", img: "be27fccf-2820-4131-c521-c075c30bc700"},
        {name: "AWS", img: "5d531977-9de6-413a-857d-9a0f4e06bb00"},
        {name: "CloudFlare", img: "69adef81-9db5-47a2-75fe-c4a7a0825800"},
        {name: "Firebase", img: "a60cecac-4102-4c6a-83b4-981ef88bf800"},
      ],
    },
    {
      title: "OS",
      stack: [
        {name: "Windows", img: "b177096b-94b3-4bff-f5f4-658512de2500"},
        {name: "MacOS", img: "b48a3244-aa2c-4237-d1d0-1f3ff21bcb00"},
        {name: "iOS", img: "8aed617f-a67e-402a-4480-0fe5084a0700"},
        {name: "Android", img: "7d8e915d-0259-4ed0-4aa0-bb2f98f79800"},
        {name: "CentOS 7", img: "c41a4f5a-c107-42c3-cee3-5ee68e5dc600"},
        {name: "Ubuntu", img: "58fe3885-de28-4d64-68a0-0c560a7a2c00"},
      ],
    },
    {
      title: "Proxy Server",
      stack: [
        {name: "NginX", img: "dc80d124-2524-48ca-ad43-19fb4d88fb00"},
        {name: "Apache", img: "ae71ad0a-5d00-4d76-323b-e83c5124f500"},
      ],
    },
    {
      title: "Library",
      stack: [
        {name: "Tensorflow", img: "dc6bd703-0672-41f4-6cc5-3a83987a7f00"},
        {name: "Numpy", img: "06190155-5347-4d72-b81d-1f8649210300"},
        {name: "Pandas", img: "21abeb00-05b0-4e8e-014f-ef59c979c300"},
        {name: "MatplotLib", img: "ee0b76d3-0533-420d-e9df-17005c06d400"},
        {name: "Express", img: "8cad4cc9-0ce9-421e-ed22-e8e8dc5f0500"},
        {name: "Mongoose", img: "dd67453e-f906-41e5-130e-75c952070900"},
        {name: "Axios", img: "b825a87a-ebe9-4795-908e-f3ae14119900"},
        {name: "JQuery", img: "507bc581-2052-43e0-4a4b-c0a14d5f7a00"},
        {name: "StyledComponent", img: "d1927d90-f9b3-4aec-a17f-48b519a0e700"},
        {name: "@Emotion", img: "c3ad9165-ded8-4066-c91e-c601abe8d400"},
      ],
    },
  ]

  return (<>
    <PageTitle title={"메인페이지"} subtitle={"안녕하세요 EsterKim 입니다"}/>
    <SectionTitle text={"프로필"} id={"profile"}/>
    <ProfileBox profileInfo={profileInfo}/>
    <Divider mt={"1rem"}/>
    <SectionTitle text={"활동"} id={"activity"}/>
    <ActivityBox />
    <Divider mt={"1rem"}/>
    <SectionTitle text={"기술스택"} id={"stacks"}/>
    <StackBox myStack={myStack} />
    <Divider mt={"1rem"}/>
  </>)
}

export default MainPage;