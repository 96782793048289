import {Route, Routes} from 'react-router-dom';
import MainPage from "@page/MainPage";
import ProjectPage from "@page/ProjectPage";
import HistoryPage from "@page/HistoryPage";

const Router = () => {

  const routeArray = [
    {path: "/", element: <MainPage />},
    {path: "/project", element: <ProjectPage />},
    {path: "/history", element: <HistoryPage />}
  ]

  return (
    <Routes>
      {
        routeArray.map((route, index) => {
          return (<Route key={index} path={route.path} element={route.element} />)
        })
      }
    </Routes>
  )
}

export default Router;